.entry-table {
  padding: 1px;
  margin: 3% 2px;

  .head {
    font-size: 18px;
    font-weight: 400;
    color: rgb(94, 93, 93);
    margin: 1px 1vw;
    padding: 5px 1vw;
    border-bottom: 1px solid #094a8a;
  }

  #filter {
    text-align: center;
    margin: 0px;
    padding: 0px 5px;
  }

  td {
    font-size: 16px;
    padding: 7px 1vw;
    text-align: left;
  }

  #row:hover {
    background-color: rgba(8, 56, 110, 0.13);
    box-shadow: 0px 1px 3px rgba(75, 75, 75, 0.596);
  }

  #filter input {
    border: 1px solid #09498a6c;
    font-size: 13px;
    color: #09498a4d;
  }

  #filter select {
    border: 1px solid #09498aa8;
    font-size: 13px;
    color: #09498a57;
  }
  #filter input[type="text"]:focus {
    border: 1px solid #042e5828;
    border-radius: 0px;
    font-size: 13px;
    color: #0d3053;
  }
}

.button-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-content: center;
  
  .add-entry-btn {
    background-color: #09497e;
    color: white;
    font-size: 14px;
    margin: 3px;
    border: none;
    padding: 2px 15px;
    font-weight: 500;

    &:hover {
      background: #345880;
      border: 2px solid #022b53;
      padding: 0px 13px;
    }
  }
}

#search {
  font-size: 13px;
  font-weight: 500;
  padding: 1px;
  border: none;
  color: #02337c;
  background: white;
  text-decoration: underline;
}

#edit {
  background: none;
  border: none;
  color: #02337c;
  text-decoration: underline;
  font-size: 15px;
}

.paginationCtrls {
  padding: 10px 2%;
  text-align: center;
  background: white;
  width: 600px;
  background: #104c8e;
  color: white;
  cursor: pointer;

  select {
    user-select: none;
    padding: 5px 7px;
    border: 1px solid gray;
    display: inline-block;
    margin: 0 5px;
  }

  .btn {
    user-select: none;
    padding: 3px 5px;
    border: 1px solid gray;
    display: inline-block;
    margin: 3px 5px;
    min-width: 30px;
    background: white;
    color: black;

    &:hover {
      background: #e0e0f0;
    }

    &.activePage {
      background: #c0c0ff;
    }

    &.disable {
      background: rgba(255, 255, 255, 0.1);
      color: lightgray;
      border-color: rgba(255, 255, 255, 0.2);
      cursor: not-allowed;

      &:hover {
        background: rgba(255, 255, 255, 0.5);
      }
    }
  }
}
